import { Button, Chip, Container, HeaderBar, TypoGraph, TagFilter } from 'components'
import styles from './riskDashboard.module.scss'
import ReactToPrint from 'react-to-print'
import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'
import { RiskDetails, RiskSummaryPanel } from './partial'
import { getNewRiskScores, getNewRiskScoresForAgent, getRiskScoresFilteredByTag, getSubtagsForFilter } from 'api'
import { isAgentLogin, isInsuranceLogin } from 'tools/auth'
import { reportException } from 'tools/logs'
import FilterComponent from 'components/filters'
import { Grid } from '@mui/material'

interface IRiskDashboardProps {}

const RiskDashboard: React.FC<IRiskDashboardProps> = (props) => {
    const [riskData, setRiskData] = useState<any>([])
    const [isLoading, setIsLoading] = useState(true)
    const [updateDate, setUpdateDate] = useState<any>()
    const [printReport, setPrintReport] = useState<boolean>(false)
    const tagCategories = localStorage.getItem('tagCategories') ? JSON.parse(localStorage.getItem('tagCategories') || '') : []

    const ref = useRef<HTMLDivElement>(null)

    const isAgent = isAgentLogin()
    const isInsurance = isInsuranceLogin()

    useEffect(() => {
        if (isAgent) {
            fetchRiskScoreData(getNewRiskScoresForAgent)
        } else {
            fetchRiskScoreData(getNewRiskScores)
        }
    }, [])

    const handleRiskFilterSelection = (selectedTags?: any , credentialStatus?: string) => {
        setIsLoading(true)
        getRiskScoresFilteredByTag(selectedTags.join(','), credentialStatus)
            .then((res: any) => {
                setRiskData(res)
            })
            .catch((error: any) => {
                reportException(error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }
    
    const fetchRiskScoreData = (riskScoreCall: any) => {
        setIsLoading(true)
        riskScoreCall()
            .then((res: any) => {
                if (res) {
                    setRiskData(res)
                    const hasRiskScore = res?.details?.filter((item: any) => {
                        return (item?.fleetScore && item?.fleetScore > 0)
                    })
                    if (hasRiskScore?.length > 0) {
                        setUpdateDate(hasRiskScore[0]?.executionDate)
                    }
                }
            })
            .catch((error: any) => {
                reportException(error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const HeaderDom = () => {
        return (
            <Container className={styles.headerContainer}>
                <Container className={styles.headerTitleContainer}>
                    <TypoGraph
                        variant="h1"
                        sx={{ marginRight: '1rem' }}
                        mb={0}
                        content="Risk Analysis"
                    />
                    {updateDate && (
                        <Chip
                            label={`Updated ${moment(updateDate).format('dddd, MMMM Do')}`}
                            variant="filled"
                            sx={{
                                backgroundColor: '#E1FAEB',
                                color: '#20693D',
                            }}
                        />
                    )}
                </Container>
                <Container className={styles.btnContainer}>
                    <ReactToPrint
                        trigger={() => <Button label="Export Report" />}
                        content={() => ref.current}
                        onBeforeGetContent={() => setPrintReport(true)}
                        onAfterPrint={() => setPrintReport(false)}
                        documentTitle={`Risk Analysis Report`}
                    />
                </Container>
            </Container>
        )
    }

    return (
        <Container>
            <HeaderBar dom={<HeaderDom />} />
            <div ref={ref}>
                {isInsurance && <Grid container spacing={2} mb={2}>
                    <Grid item xs={12} display={"flex"} alignItems="start" flexDirection={"column"}>
                        <FilterComponent
                            onFetchRiskScoreData={fetchRiskScoreData}
                            onHandleFilterSelection={handleRiskFilterSelection}
                            riskScoresFilters={true}
                            carriersFilters={true}
                        />
                    </Grid>
                </Grid>}
                <RiskSummaryPanel 
                    summaryData={riskData?.summary}
                    print={printReport}
                    isLoading={isLoading}
                    isAgent={false}
                />
                <RiskDetails
                    detailsData={riskData?.details}
                    print={printReport}
                    isLoading={isLoading}
                    isAgent={false}
                />
            </div>
        </Container>
    )
}

export default RiskDashboard