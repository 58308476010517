import React, { useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import { Blade, Button } from 'components'
import { InputFormField } from "components/hookForm"
import { updateBulkUpdateGZC } from 'api'
import { Container, Toast as toast } from 'components'
import { reportException } from 'tools/logs';
import { useForm, FormProvider} from 'react-hook-form'
import { validateLengthGZC } from 'tools/validators'

export interface ApiRefBulkGZC { setListIndexGZC: (params: any) => void }
interface ApiProps { handleSaveSon: () => void}
interface ApiRowProps{default: any}

interface InputManualGZC { 
  getValue: () => any;
  validateForm?: () => Promise<boolean>;  
}

const InputBulk = forwardRef<InputManualGZC,ApiRowProps>((props, ref) => {
  const getValue = () => {
    return getValues('manual_GZC')
  }

  const methods = useForm<any>({
    reValidateMode: 'onChange',
    mode: 'all',
    defaultValues: {
      manual_GZC: props.default
    }
  })
  const { getValues, formState: { errors } } = methods
 
  useImperativeHandle(ref, () => ({ 
    getValue,
    validateForm: () => methods.trigger("manual_GZC")
  }))

  return (<div style={{ 'display': 'flex', 'flexDirection': 'column' }}>
    <p className='text-sm text-muted-foreground' style={{ 'color': '#757575' }}>
      Enter a new Manual GZC value for the selected entries
    </p>
    <FormProvider {...methods}>
      <InputFormField
        id="manualGZC_input"
        name="manual_GZC"
        validate={validateLengthGZC}
        className="border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </FormProvider>
  </div>
  )
})

const BulkEditManualGZC = React.forwardRef<ApiRefBulkGZC, ApiProps>((props, ref) => {
  const [manualGZPDefault, setManualGZPDefault] = useState('')
  const [hasChecked, setHasChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [disabledOpenBladeGZC, setDisabledOpenBladeGZC] = useState({
    disabledOpenBladeGZC: true,
    listIndexGZC: []
  })

  const inputRef = useRef<InputManualGZC>(null)

  const handleCloseEditBulkManualGZC = () => {
    setHasChecked(false)
  }

  const handleSubmitBulkManualGZC = async () => {
    // Validate the form first
    const isValid = await inputRef.current?.validateForm?.();

    if (!isValid) {
      return; // Stop submission if validation fails
    }else{    
    const payload = {
      listAssetEldId: disabledOpenBladeGZC.listIndexGZC ? disabledOpenBladeGZC.listIndexGZC : null,
      manualGzc: inputRef.current?.getValue() ? inputRef.current?.getValue() : null
    }
    setManualGZPDefault(inputRef.current?.getValue())
    setLoading(true)

    updateBulkUpdateGZC(
      payload,
      localStorage.getItem('carrierId') ?? '',
      localStorage.getItem('carrierCodeType') ?? '',
      localStorage.getItem('carrierCodeValue') ?? ''
    )
      .then((res: any) => {
        toast({ id: 'updateBulkGzc', type: 'success', subTitle: res?.message })
      })
      .catch((error: any) => {
        reportException(error)
      })
      .finally(() => {
        setLoading(false)
        handleCloseEditBulkManualGZC()
        props.handleSaveSon()
      })
    }
  }

  const handleOpenModalBulkGZC = () => {
    if (disabledOpenBladeGZC.listIndexGZC.length > 0) {
      setHasChecked(true)
    }
  }

  const setListIndexGZC = (index: any) => {
    setDisabledOpenBladeGZC({
      disabledOpenBladeGZC: index.length == 0,
      listIndexGZC: index
    }
    )
  }

  useImperativeHandle(ref, () => ({
    setListIndexGZC

  }))
  return (<>
    <Container>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1em' }}>
        <Button
          disabled={disabledOpenBladeGZC.disabledOpenBladeGZC}
          variant="contained"
          color="primary"
          label={"Bulk Edit"}
          onClick={handleOpenModalBulkGZC}
        />
      </div>
    </Container> 

    <Blade
      open={hasChecked}
      title={"Bulk Edit Manual GZC"}
      onClose={handleCloseEditBulkManualGZC}
      bodyNode={<InputBulk ref={inputRef} default={manualGZPDefault}></InputBulk>}
      footerBtns={{
        primary: {
          id: "createOrUpdateBulkManualGZC",
          label: 'Save',
          onClick: handleSubmitBulkManualGZC,
          disabled: false,
          loading: loading
        },
        secondary: {
          id: "closeBulkManualGZC",
          label: 'Close',
          variant: 'outlined',
          onClick: handleCloseEditBulkManualGZC,
        },
      }}
    />
  </>
  )
})

export default BulkEditManualGZC 
