import { Blade, Container, DataGridTable } from 'components'
import React, { useState, useRef } from 'react'
import BulkEditManualGZC,{ ApiRefBulkGZC } from './bulkEditManualGZC'
import styles from '../radiusOfOperation.module.scss'
import { useGridApiRef ,GridRenderCellParams} from '@mui/x-data-grid'
import { numberComparator, numberOrStringComparator } from 'tools/comparators'
import EditIndividualManualGZC from './editIndividualManualGZC'

interface IGarageZipcodeConfigProps {
  isOpen: boolean
  currentData: any
  updateCurrentData: (data: any) => void
  loading: boolean
  onClose: () => void
  onSave: () => void
  btnLoader: boolean
  updateDashboardAfterUpdate: () => void
}

const GaragingZipcodeConfig: React.FC<IGarageZipcodeConfigProps> = (props) => {
  const { isOpen, currentData, updateCurrentData, loading, onClose, onSave, btnLoader } =
    props
  const [disableBtn, setDisableBtn] = useState(true)
  const apiRef = useGridApiRef() 
  const apiRefBulkGZCOrigin = useRef<ApiRefBulkGZC>(null)

  const handleEditManualGzc = (updatedRow: any) => {
    updateCurrentData(updatedRow)
    setDisableBtn(false)
    return updatedRow
  }

  const handleClose = () => {
    setDisableBtn(true)
    onClose()
  }

  const setListIndexGZC = (paramBulk: any) =>{
    if(apiRefBulkGZCOrigin && apiRefBulkGZCOrigin.current){
      apiRefBulkGZCOrigin.current?.setListIndexGZC(paramBulk) 
    }
  }

  const BladeContent = () => {

    const gzcColumnsDefinition = [   
      {
        field: 'assetEldId',
        headerName: 'Asset ID',
        minWidth: 100,
        flex: 1,
        sortable: true,
        sortComparator: numberOrStringComparator,
      },
      {
        field: 'vin',
        headerName: 'Vehicle Identifier',
        minWidth: 150,
        flex: 1.5,
        sortable: true,
        sortComparator: numberOrStringComparator,
      },
      {
        field: 'eldProvider',
        headerName: 'ELD Provider',
        minWidth: 150,
        flex: 1.5,
        sortable: true,
        sortComparator: numberOrStringComparator,
      },
      {
        field: 'automaticGzc',
        headerName: 'Automatic GZC',
        minWidth: 80,
        flex: 1,
        sortable: true,
        sortComparator: numberComparator,
      },
      {
        field: 'manualGzc',
        headerName: 'Manual GZC',
        minWidth: 80,
        flex: 1,
        sortable: true,
        sortComparator: numberComparator,
      },
      {
        field: 'editIndividual',
        headerName: '',
        minWidth: 80,
        flex: 1,
        editable: false,
        renderEditCell:(params: GridRenderCellParams) => {return null},
        renderCell: (params: GridRenderCellParams) => {
          return (          
           <EditIndividualManualGZC
            row={params.row}
            handleSaveSon = {props.updateDashboardAfterUpdate} 
           />          
          )
        }    
      },
    ]
    const gzcListTableColumns = {
      columns: gzcColumnsDefinition.map(
        ({
          field,
          headerName,
          minWidth,
          flex,
          sortable,
          editable,
          renderCell,
          renderEditCell,
        }) => ({
          field,
          headerName,
          minWidth,
          flex,
          sortable,
          editable,
          renderCell,
          renderEditCell,
        })
      ),
    } 
    return (      
      <Container className={styles.bladeBodyContainer} >        
        <BulkEditManualGZC 
          ref={apiRefBulkGZCOrigin}
          handleSaveSon = {props.updateDashboardAfterUpdate}
        />
        <Container className={styles.resultsContainer} sx={{ mt: 1 }}>
          <DataGridTable
            apiRef={apiRef}
            loading={loading}
            data={currentData || []}
            columns={gzcListTableColumns.columns}
            columnVisibilityModel={{}}
            getRowId={(row: any) => row?.assetEldId}
            showFilter={true}
            multiSearchFilter={false}
            showTitle={false}
            checkboxSelection={true}
            reduceHeight={260}
            editMode={'row'}
            processRowUpdate={(updatedRow: any, originalRow: any) =>
              handleEditManualGzc(updatedRow)
            }
            onProcessRowUpdateError={() => {}}
            handleCheckboxSelection={(param) => {setListIndexGZC(param)}}          
          />
        </Container>
      </Container>
    )
  }

  return (
    <>
      <Blade
        title="Garaging Zip Code Configuration"
        open={isOpen}
        onClose={handleClose}
        size={950}
        bodyNode={<BladeContent/>}        
        footerBtns={{          
          primary: {
            label: 'Cancel',
            onClick: handleClose,
          },
        }}
      />
    </>
  )
}
export default GaragingZipcodeConfig
