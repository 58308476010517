import React, { useEffect, useState } from 'react'
import { getUserEmailOptions} from 'api'
import { Container, HeaderBar } from 'components'
import { CircularProgress } from "@mui/material"
import styles from './communicationCenter.module.scss'
import strings from 'strings'
import { reportException } from 'tools/logs'
import { NotificationSetting } from './partial'

const t = strings.COMMUNICATION_CENTER

const CommunicationCenter: React.FunctionComponent = () => {
  const [loading, setLoading] = useState(false)
  const [selectUserEmailList, setSelectUserEmailList] = useState([])

  useEffect(() => {
    setLoading(true)
    getUserEmailOptions()
      .then((response: any) => {
        setSelectUserEmailList(response)
      })
      .catch((error: any) => {
        reportException(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])
  
  return (
    <Container>
      <HeaderBar title={t.COMMUNICATION_CENTER} />
      {loading ? (
          <Container className={styles.loader}>
            <CircularProgress />
          </Container>
        ) : ( 
          <>
            <NotificationSetting
              id="riskNotificationSetting"
              type={t.RISK_SCORE_NOTIFICATION_TYPE}
              title="Risk Score"
              emailOptions={selectUserEmailList}
            />
            <NotificationSetting
              id="disconnectedCarrierNotificationSetting"
              type={t.DISCONNECTED_CARRIERS_NOTIFICATION_TYPE}
              title="Disconnected Carriers"
              emailOptions={selectUserEmailList}
            />
          </>
        )}
    </Container>
  )
}

export default CommunicationCenter


