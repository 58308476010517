import { useCallback, useEffect, useMemo, useState } from "react";
import {
    Button, TextField, Box, Typography, Grid, Autocomplete
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import Blade from "components/blade";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import strings from "strings";
import { isAgentLogin, isInsuranceLogin } from "tools/auth";
import { getNewRiskScores, getNewRiskScoresForAgent, getSubtagsForFilter } from "api";
import { reportException } from "tools/logs";
import LoadingWrapper from "components/loadingWrapper";
import { getCredentialStatuses } from "tools/helper";

const t = strings.FILTERS

interface IFilterComponent {
    onFetchRiskScoreData?: (riskScoreCall: any) => void
    onHandleFilterSelection?: (selectedTags?: any, credentialStatus?: string) => void
    riskScoresFilters: boolean
    carriersFilters: boolean
}

const FilterComponent: React.FC<IFilterComponent> = (props) => {
    const { onFetchRiskScoreData, onHandleFilterSelection, riskScoresFilters, carriersFilters } = props

    const tags: Record<string, string> = localStorage.getItem('tagCategories')
        ? JSON.parse(localStorage.getItem('tagCategories') as string)
        : {}

    const [openBlade, setOpenBlade] = useState(false)
    const [selectedTags, setSelectedTags] = useState<any>([])
    const [tagLoading, setTagLoading] = useState(false)
    const [subTags, setSubTags] = useState<Record<string, string>>({})
    const [selectedSubTags, setSelectedSubTags] = useState<any>([])
    const [selectedCredentials, setSelectedCredentials] = useState([])

    const isAgent = isAgentLogin()
    const isInsurance = isInsuranceLogin()

    const callSubTagsList = useCallback((selectedTags: any, currentSelectedSubTags?: any) => {
        const selectedTagIds = selectedTags.map((tag: any) => tag.id)
        getSubtagsForFilter(selectedTagIds.join(','))
            .then((res: any) => {
                if (res.tagSubCategories !== null && res.tagSubCategories !== undefined) {
                    if (currentSelectedSubTags) {
                        const mergedSubTags = { ...currentSelectedSubTags, ...res.tagSubCategories }
                        setSubTags(mergedSubTags)
                    } else {
                        setSubTags(res.tagSubCategories)
                    }
                }
            })
            .catch((error: any) => {
                reportException(error)
            })
            .finally(() => {
                setTagLoading(false)
            })
    }, [])

    const handleTagCategorySelection = (selectedTags: any) => {
        setTagLoading(true)
        if (selectedTags.length) {
            callSubTagsList(selectedTags)
        } else {
            setSubTags({})
            setSelectedSubTags([])
            setTagLoading(false)
        }
    }

    const tagArray = Object.entries(tags).map(([id, name]: [string, string]) => ({ id, name }))
    const sortedTags = useMemo(() => {
        return tagArray
            .map((tags) => {
                const firstLetter = tags.name[0].toUpperCase()
                return {
                    ...tags,
                    firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                }
            })
            .sort((a, b) => a.name.localeCompare(b.name))
    }, [])

    const sortedSubTags = useMemo(() => {
        if (!subTags || Object.keys(subTags).length === 0) return []
        return Object.entries(subTags)
            .map(([id, name]) => ({
                id,
                name,
                firstLetter: /[0-9]/.test(name[0].toUpperCase()) ? '0-9' : name[0].toUpperCase()
            }))
            .sort((a, b) => a.name.localeCompare(b.name))
    }, [subTags])

    const sortedCredentials = useMemo(() => {
        const credentialStatus = getCredentialStatuses()
        return credentialStatus
            .map((credentials) => {
                const firstLetter = credentials.status[0].toUpperCase()
                return {
                    ...credentials,
                    firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                }
            })
            .sort((a, b) => a.status.localeCompare(b.status))
    }, [])

    const clearFilters = () => {
        setSelectedTags([])
        setSelectedCredentials([])
        setSelectedSubTags([])
        setSubTags({})
        if (riskScoresFilters && onFetchRiskScoreData) {
            setTagLoading(true)
            onFetchRiskScoreData(isAgent ? getNewRiskScoresForAgent : getNewRiskScores)
            setTagLoading(false)
        }
    }

    const handleTagsFilter = (event: any, newValue: any) => {
        setSelectedTags(newValue)
        handleTagCategorySelection(newValue)
    }

    const handleSubTagsFilter = (event: any, newValue: any) => {
        setSelectedSubTags(newValue)
    }

    const handleCredentialsFilter = (event: any, newValue: any) => {
        setSelectedCredentials(newValue)
    }

    const onApplyFilters = () => {
        if ((riskScoresFilters || carriersFilters) && onHandleFilterSelection) {
            const selectedSubTagIds = selectedSubTags.map((tag: any) => tag.id)
            const statuses = selectedCredentials
                .map((credential: any) => credential.status)
                .join(',')
            onHandleFilterSelection(selectedSubTagIds, statuses)
            setOpenBlade(false)
        }
    }

    const isApplyFiltersDisabled = () => {
        return !(
            (riskScoresFilters && selectedTags?.length > 0 && selectedSubTags?.length > 0) ||
            (carriersFilters && selectedCredentials?.length > 0)
        )
    }


    const FilterOptions = ({ label, options, value, onChange, multiple }: any) => (
        <Grid item xs={12} sm={12} display={"flex"} alignItems="start" flexDirection={"column"} gap={2}>
            <Typography variant="body1">{label}</Typography>
            <Autocomplete
                multiple
                sx={{ width: "100%" }}
                options={options}
                groupBy={(option) => option.firstLetter}
                getOptionLabel={(option) => option.status || option.name}
                filterSelectedOptions={!!multiple}
                value={value}
                onChange={onChange}
                renderInput={(params) => <TextField {...params} label={`Filter by ${label}`} placeholder={`Select ${label}`} />}
            />
        </Grid>
    )

    const FiltersContent = (_props: any) => {
        return (
            <LoadingWrapper isLoading={tagLoading}>
                <Box p={2}>
                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} display={"flex"} alignItems="start" flexDirection={"column"}>
                            <Typography variant="h5">{t.TITLE}</Typography>
                            <Typography color={"gray"} variant="subtitle2">{t.DESCRIPTION}</Typography>
                        </Grid>
                        <Grid item xs={12} display="flex" gap={2} justifyContent={"end"}>
                            <Button
                                sx={{ borderRadius: 25 }}
                                startIcon={<Clear />}
                                variant="outlined"
                                color="error"
                                onClick={clearFilters}>
                                {t.CLEAR_BUTTON}
                            </Button>
                        </Grid>
                        {riskScoresFilters && (
                            <>
                                <FilterOptions
                                    label={t.TAGS_LABEL}
                                    options={sortedTags}
                                    value={selectedTags}
                                    onChange={handleTagsFilter}
                                    multiple={true}
                                />
                                {Object.keys(subTags).length > 0 && (
                                    <FilterOptions
                                        label={t.SUB_TAGS_LABEL}
                                        options={sortedSubTags}
                                        value={selectedSubTags}
                                        onChange={handleSubTagsFilter}
                                        multiple={true}
                                    />
                                )}
                            </>
                        )}
                        {carriersFilters &&
                            <FilterOptions
                                label={t.CREDENTIALS_LABEL}
                                options={sortedCredentials}
                                value={selectedCredentials}
                                onChange={handleCredentialsFilter}
                                multiple={true}
                            />
                        }
                    </Grid>
                </Box>
            </LoadingWrapper>
        )
    }

    return (
        <>
            <Button
                sx={{ borderRadius: 25, minWidth: 120 }}
                variant="contained"
                color="primary"
                size="small" onClick={() => setOpenBlade(true)}
                startIcon={<FilterAltIcon />}
                disabled={isAgent}
            >
                {t.FILTERS}
            </Button>
            <Blade
                title={t.FILTERS}
                open={openBlade}
                onClose={() => {
                    setOpenBlade(false)
                }}
                bodyNode={<FiltersContent />}
                footerBtns={{
                    primary: {
                        id: "ApplyFiltersBtn",
                        label: t.APPLY_BUTTON,
                        onClick: () => {
                            onApplyFilters()
                        },
                        disabled: isApplyFiltersDisabled()
                    },
                    secondary: {
                        id: "CloseFiltersBladeBtn",
                        label: t.CLOSE_BUTTON,
                        variant: 'outlined',
                        onClick: () => {
                            setOpenBlade(false)
                        },
                    }
                }}
            />
        </>
    )
}

export default FilterComponent