import LocalizedStrings from 'react-localization'

interface ObjectString {
  [index: string]: any
}

let strings = new LocalizedStrings({
  en: {
    /**
     * Place all the strings here to reuse and to maintain the strings in the application
     */
    CREATE: 'Create',
    EDIT: 'Edit',
    DELETE: 'Delete',
    VIEW: 'View',
    UPDATE: 'Update',
    SAVE: 'Save',
    CLOSE: 'Close',
    STORY_BOOK: 'View Document',
    DEMO_TABLE_COMPONENT: 'Demo Table Component',
    PRIMARY_ACTION: 'Primary Action',
    SECONDARY_ACTION: 'Secondary Action',
    CONTROLLER_LIST: 'List of Controllers',
    INPUT_CONTROLLER: 'Input Field Controller',
    SINGLE_SELECT_CONTROLLER: 'Single Select Controller',
    CHECKBOX_CONTROLLER: 'Checkbox Controller',
    SETTINGS: 'Settings',

    USER_NAME: 'User Name',
    ADDRESS: 'Address',
    STATE: 'State',
    CITY: 'City',
    ZIP: 'Zip',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    MOBILE_NUMBER: 'Mobile Number',
    ROLE: 'Role',
    EMAIL_TEMPLATE: 'Email Template',

    PLEASE_ENTER_A_VALID_EMAIL: 'Please enter a valid email',
    PLEASE_ENTER_A_VALID_EMAIL_LIST: 'Please enter valid emails separated by commas ',

    COMPANY: 'Company',
    SESSION_HAS_TIMED_OUT: 'Session has timed out',
    PLEASE_RE_ENTER_YOUR_CREDENTIALS:
      'Please re-enter your credentials to login again.',
    ERROR_BOUNDRY_HEADER: 'We ran into a problem.',
    ERROR_BOUNDRY_BODY:
      'Try to refresh your browser or use your browser back button to go back to the previous page.',
    PAGE_404_TITLE: '404',
    PAGE_404_SUBHEADER: 'This page cannot be found',
    PAGE_404_MESSAGE: `The page you are looking for might have been removed,
         had its name changed, or is temporarily unavailable.`,
    PASSWORD_ERROR_FORMAT:
      'Password must be at least 8 characters, include at least one digit, one capital letter and at least one special character (@, $, !, #).',
    EMAIL_ERROR_FORMAT:
      'The email format is not correct.',
    EMAIL_ERROR_FORMAT_LIST:'One or more of the emails format is not correct.',
    MANUAL_GZC_LENGTH:'Manual GZC requiere to be a 5-digit number',
    LOGIN: 'Login',
    LOGOUT: 'Log Out',
    PROFILE: 'Profile',
    PAGE_UNDER_CONSTRUCTION: 'Page Under Construction',
    READ_BLOG: 'Read Our Blog',
    REVIEW_CHANGELOG: 'Review full changelog',
    EMAIL_ADRESS: 'Email Address',
    EMAIL: 'Email',
    SAFETY_EMAIL: 'Safety Contact Email',
    PASSWORD: 'Password',
    TRUCKER_CLOUD_TITLE:
      'The fastest way to connect ELD data to any application.',
    FORGOT_PASSWORD: 'Forgot Password?',
    RECOVER_PASSWORD: 'Recover Password',
    NEW_PASSWORD: 'New Password',
    CONFIRM_PASSWORD: 'Confirm Password',
    RESET_PASSWORD: 'Reset Password',
    BACKTO_LOGIN: 'Back to Login',
    NOT_REGISTERED: 'Not registered, and ',
    WANT_TO_SEE_DEMO: 'want to see a demo?',
    COMPANY_NAME: 'Company name',
    ADDRESS_1: 'Address 1',
    ADDRESS_2: 'Address 2',
    MENU: {
      DASHBOARD: 'Risk Dashboard',
      CARRIERS: 'Carriers',
      ACCOUNTS: 'My Accounts',
      REPORTING: 'Reporting',
      HELP: 'Help',
      SETTINGS: 'Settings',
      PROFILE: 'Profile',
      FLEET_REPORTING: 'Fleet Reporting',
      CLAIMS_SEARCHING: 'Claims Searching'
    },
    ROUTES: {
      LOGIN: '/login',
      WIDGET: '/tcwidget/*',
      DASHBOARD: '/dashboard/*',
      CARRIERS: '/carriers/*',
      CARRIER_DETAILS: '/details',
      ACCOUNTS: '/accounts/*',
      REPORTING: '/reporting/*',
      CLAIMS_SEARCHING: '/claims-searching/*',
      FLEET_REPORTING: '/fleet-reporting',
      SETTINGS: '/settings/*',
      SETTINGS_COMPANY_CONFIG: '/company-config',
      SETTINGS_EMAIL_TEMPLATES: '/email-templates',
      SETTINGS_EMAIL_ACCOUNTS: '/email-accounts',
      SETTINGS_WHITE_LABEL: '/white-label',
      SETTINGS_USERS: '/users',
      SETTINGS_AGENTS: '/agents',
      SETTINGS_TAGS: '/tags',
      CHANGE_PASSWORD: '/password-change',
      PROFILE: '/profile/*',
      COB: '/cob/*',
      COB_DIRECT: '/cob/direct/*',
      COB_INSTALL: '/cob/install/*',
      COB_FV: '/cob/fv/*',
      CURRENT_PATH: '/',
      WILDCARD: '*',
      SETTINGS_COMMUNICATION_CENTER: '/communication-center',
    },
    CARRIERS: {
      CARRIERS: 'Carriers',
      INVITE_CARRIERS: 'Invite Carriers',
      MY_CARRIERS: 'My Carriers',
      INVITED_CARRIERS: 'Invited Carriers',
      EDIT_CARRIER: 'Edit Carrier',
      CARRIER_NAME: 'Carrier Name',
      DOT: 'DOT',
      MC: 'MC',
      SCAC: 'SCAC',
      UUID: 'UUID',
      CUID: 'CUID',
      POLICY_ID: 'Policy ID'
    },
    CARRIERS_MANAGEMENT: {
      MY_CONNECTED_ACCOUNTS: 'My Connected Accounts',
      INVITE_CARRIER: 'Invite Accounts',
      INVITATION_TITLE: 'Invite an Account',
      PLEASE_ENTER_A_VALID_EMAIL: 'Please enter a valid email for the account',
      CONFIRMATION_DIALOG_TITLE: 'Confirm Account Invitation',
      CONFIRMATION_DIALOG_MESSAGE: 'This operation will create an invitation link for the motor carrier, and the email you set can\'t be invited again. Are you sure you want to continue?',
      AGREE_BUTTON_TEXT: 'Agree',
      DISAGREE_BUTTON_TEXT: 'Disagree',
    },
    USER_MANAGEMENT: {
      USER_MANAGEMENT: 'User Management',
      USER: 'User',
      USERS: 'Users',
      CREATE_USER: 'Create User',
      EDIT_USER: 'Edit User',
      USER_INFORMATION: 'User Information',

      TEMPLATE_NAME: 'Template Name',
      TEMPLATE_TYPE: 'Template Type',
      EMAIL_ACCOUNTS: 'Email Accounts',
      CONTENT: 'Content',
    },
    AGENT_MANAGEMENT: {
      AGENT_MANAGEMENT: 'Agent Management',
      AGENT: 'Agent',
      AGENTS: 'Agents',
      CREATE_AGENT: 'Create Agent',
      EDIT_AGENT: 'Edit Agent',
      AGENT_INFORMATION: 'Agent Information',

      TEMPLATE_NAME: 'Template Name',
      TEMPLATE_TYPE: 'Template Type',
      EMAIL_ACCOUNTS: 'Email Accounts',
      CONTENT: 'Content',
      ASSIGN: 'Assign',
      UNASSIGN: 'Unassign',
      AVAILABLE_CARRIERS: 'Available Motor Carriers',
      ASSIGNED_CARRIERS: 'Assigned Motor Carriers'
    },
    TAG_MANAGEMENT: {
      TAG_MANAGEMENT: 'Tag Management',
      TAG: 'Tag',
      TAGS: 'Tags',
      CREATE_TAG: 'Create Tag',
      EDIT_TAG: 'Edit Tag',
      TAG_NAME: 'Tag Name',
      TAG_CATEGORY: 'Category',

      TEMPLATE_NAME: 'Template Name',
      TEMPLATE_TYPE: 'Template Type',
      CONTENT: 'Content',
      ASSIGN: 'Assign',
      UNASSIGN: 'Unassign',
      AVAILABLE_CARRIERS: 'Available Motor Carriers',
      ASSIGNED_CARRIERS: 'Assigned Motor Carriers'
    },
    CLAIMS_SEARCHING: {
      CARRIER_FOR_CLAIMS: 'Select a Carrier for Claims',
      CARRIERS_SEARCH: 'Available Carriers',

      SAFETY_EVENTS: 'Safety Events',
      ROUTES: 'Routes',
      SEARCH_SAFETY_LABEL: 'Search for Safety Events',
      NO_SAFETY_LABEL: 'No Safety Events Available',
      NO_SAFETY_TOOLTIP: 'Fleet does not have Safety Events',

      SEARCH:'Search',
      SEARCH_VEHICLES: 'Search Vehicles', 

      START_DATE_LABEL: 'Start Date and Time',
      END_DATE_LABEL: 'End Date and Time',
      END_DATE_ERROR: 'End Date must be at least 1 minute after the Start Date.',

      SAFETY_EVENTS_LIMIT: 600,
      SAFETY_EVENTS_ALERT_CONTENT: (limit: number) => `For optimal viewing, only the ${limit} most recent events are displayed. To see other events, try adjusting the filters.`
    },
    CLAIMS_DETAILS: {
      EVENT: 'Event',
      ID: 'ID',
      TIME: 'Time',
      SPEED: 'Speed',
      LATITUDE: 'Latitude',
      LONGITUDE: 'Longitude',
      VEHICLE_DETAILS: 'Vehicle Details',
      DRIVER_DETAILS: 'Driver Details',
      GFORCE_DATA: 'G-Force Data',
      CAMERA_POSITION: 'Camera Position',
      NAME: 'Name',
      TYPE: 'Type',
      LICENSE_PLATE: 'License Plate',
      VIN: 'VIN',
      SERIAL_NO: 'Serial Number',
      MAKE: 'Make',
      MODEL: 'Model',
      MODEL_YEAR: 'Year',
      LICENSE_NO: 'License No',
      STATE: 'State',
      PHONE: 'Phone',
      EMAIL: 'Email',
      TIME_LABEL: 'Time',
      VALUE: 'Value',
      VIDEO: 'Video',
      VIDEO_NOT_AVAILABLE: 'No video available for this event.',
      VIDEO_EXPIRED: 'The video for this event has expired.',
      NO_GFORCE_DATA: 'No G-Force data available.',
      PREVIOUS: 'Previous',
      NEXT: 'Next',
    },
    COMPANY_CONFIG: {
      COMPANY_NAME: 'Company Name',
      COMPANY_ID: 'Company ID',
      COMPANY_TYPE: 'Company Type',
      MC: 'MC',
      DOT: 'DOT',
      SCAC: 'SCAC',
      SUPPORT_EMAIL: 'Support Email',
      EMAIL_SUBSCRIPTION: 'Email Subscription',
      ADDRESS: 'Address',
      WEBSITE: 'Website',
      PHONE_NUMBER: 'Phone Number',
    },
    EMAIL_TEMPLATES: {
      EMAIL_TEMPLATES: 'Email Templates',
      NEW_TEMPLATE: 'New Template',
      TEMPLATE_NAME: 'Template Name',
      TEMPLATE_TYPE: 'Template Type',
      EMAIL_ACCOUNTS: 'Email Accounts',
      CONTENT: 'Content',
      EDIT_TEMPLATE: 'Edit Template',
      CREATE_TEMPLATE: 'Create Template',
    },
    EMAIL_ACCOUNTS: {
      EMAIL_ACCOUNTS: 'Email Accounts',
      NEW_ACCOUNT: 'New Account',
      SENDER_NAME: 'Sender Name',
      SENDER_EMAIL: 'Sender Email',
      EDIT_ACCOUNT: 'Edit Account',
      CREATE_ACCOUNT: 'Create Account',
    },
    COMMUNICATION_CENTER: {
      COMMUNICATION_CENTER: 'Communication Center',
      RISK_SCORE_NOTIFICATION_TYPE: 'RISKSCORE',
      DISCONNECTED_CARRIERS_NOTIFICATION_TYPE: 'DISCONNECTED_CARRIERS',
      NOTIFICATION_SETTINGS: 'Notification Settings',
      NOTIFICATION_SETTINGS_UPDATE_SUCCESS:'Notification Settings updated successfully',
      NOTIFICATION_SETTINGS_CREATE_SUCCESS:'Notification Settings created successfully',
      NEW_CONFIGURATION: 'New Configuration',
      REPORT: 'Report Name',
      COMPANY_NAME: 'Company Name',
      EDIT_CONFIGURATION: 'Edit Configuration',
      CREATE_CONFIGURATION: 'Create Configuration',
      FREQUENCY: 'Frequency',
      USER_MAIL: 'Carriers\' Notification Email',
      USER_MAIL_CC: 'CC Email',
      CREATE_SETTING: 'Create Setting',
      UPDATE_SETTING:'Update Setting',
    },
    WHITE_LABEL: {
      WHITE_LABEL: 'White Label',
    },
    PROFILE_STRING: {
      PROFILE: 'Profile',
      USER_NAME: 'User Name',
      USER_TYPE: 'User Type',
      COMPANY_NAME: 'Company Name',
      EMAIL: 'Email',
      PROFILE_DETAILS: 'Profile Details',
      PASSWORD: 'Password',
      NEW_PASSWORD: 'New Password',
      REQUEST_PASSWORD: 'Request Password Change',
      REQUEST_PASSWORD_INTRO: 'If you want to change your current password, press the button below and you will receive an email with a link to complete the process.',
      CONFIRM_PASSWORD: 'Confirm Password',
      CHANGE_PASSWORD: 'Change Password',
      PASSWORD_FORMAT:
        'Password must be at least 8 characters, including at least one digit and at least one special character (that is not a digit or letter).',
    },
    OFFBOARDING: {
      OFFBOARDING: 'Offboard',
      CONFIRMATION_DIALOG_TITLE: 'Confirm Carrier Offboarding',
      CONFIRMATION_DIALOG_MESSAGE: "You will no longer be able to access this carrier's information. Are you sure you want to continue?",
      AGREE_BUTTON_TEXT: 'Agree',
      DISAGREE_BUTTON_TEXT: 'Disagree',
    },
    CARRIER_STATUS_CHANGE: {
      TYPES: {
        PAUSE: 'pause',
        RESUME: 'resume',
        OFFBOARD: 'offboard',
        RECONNECT: 'reconnect'
      },

      PAUSE_TITLE: 'Pause Carrier',
      CONFIRMATION_PAUSE_TITLE: 'Confirm Carrier Pause',
      CONFIRMATION_PAUSE_MESSAGE: "Are you sure you want to pause pulling data for this carrier? You can resume at any time.",

      RESUME_TITLE: 'Resume Carrier',
      CONFIRMATION_RESUME_TITLE: 'Confirm Carrier Resume',
      CONFIRMATION_RESUME_MESSAGE: "Are you sure you want to resume pulling data for this carrier?",

      OFFBOARD_TITLE: 'Offboard Carrier',
      CONFIRMATION_OFFBOARD_TITLE: 'Confirm Carrier Offboard',
      CONFIRMATION_OFFBOARD_MESSAGE: "Are you sure you want to offboard this carrier? This action is irreversible.",

      RECONNECT_TITLE: 'Reconnect Carrier',
      CONFIRMATION_RECONNECT_MESSAGE: 'You can generate a new invitation URL to reconnect the Carrier.',
      RECONNECT_TEXTFIELD_PLACEHOLDER: 'Generated URL will appear here',
      RECONNECT_COPY: 'Copy to clipboard',
      RECONNECT_GENERATE_INVITATION: 'Generate and Send Invitation URL',

      CONFIRM_BUTTON_TEXT: 'Confirm',
      CANCEL_BUTTON_TEXT: 'Cancel',
    },
    FILTERS: {
      TITLE: "Filter Options",
      DESCRIPTION: "Configure filters to narrow down your Fleet Data",
      CLEAR_BUTTON: "Clear Filters",
      FILTERS: "Filters",

      APPLY_BUTTON: "Apply Filters",
      CLOSE_BUTTON: "Close",

      TAGS_LABEL: "Tags",
      SUB_TAGS_LABEL: "Sub Tags",
      CREDENTIALS_LABEL: "Credential Status",
      RISK_LABEL: "Risk Score",
      TIME_LABEL: "Time Range",
    },
    REQUIRE_2FA: 'Require 2FA',
    MOBILE_2FA: 'Mobile 2FA',
    ALLOW_APP_SIGNUP: 'Allow App Sign up',
    CREATE_COMPANY: 'Create company',
    UPDATE_COMPANY: 'Update company',
    DELETE_COMPANY: 'Delete company',
    RECONNECT_CARRIER: 'RECONNECT',
  },
})

export const addStrings = (obj: object, lang: string = 'en') => {
  const original = strings
  strings.setContent({
    [lang]: mergeDeep(original, obj),
  })
  return strings
}

const isObject = (item: object | string) => {
  return item && typeof item === 'object' && !Array.isArray(item)
}

function mergeDeep(source: ObjectString, target: ObjectString) {
  let output: ObjectString = Object.assign({}, target)
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target))
          Object.assign(output, {
            [key]: source[key],
          })
        else output[key] = mergeDeep(target[key], source[key])
      } else {
        Object.assign(output, {
          [key]: source[key],
        })
      }
    })
  }
  return output
}

export default strings
