import moment from 'moment'
import colors from 'theme/colors'

const getInvitedCarrierData = (data: any) => {
  const codeTypes = ['TCID', 'DOT', 'MC', 'SCAC', 'UUID']
  const tableData = data?.map((item: any) => {
    const getUuid = () => {
      const val = item?.carrierInfo?.carrierCodes.filter(
        (item: any) => item.codeType === 'UUID'
      )[0]?.codeValue
      return !val ? '-' : val
    }
    const getScac = () => {
      const val = item?.carrierInfo?.carrierCodes.filter(
        (item: any) => item.codeType === 'SCAC'
      )[0]?.codeValue
      return !val ? '-' : val
    }
    const getCuid = () => {
      const val = item?.carrierInfo?.carrierCodes.filter((item: any) => {
        return !codeTypes.includes(item.codeType)
      })[0]?.codeValue
      return !val ? '-' : val
    }
    return {
      carrierId: item?.carrierInfo?.carrierId,
      carrierName: item?.carrierInfo?.carrierName,
      firstName: item?.contactInfo?.firstName,
      lastName: item?.contactInfo?.lastName,
      email: item?.contactInfo?.email,
      dot:
        item?.carrierInfo?.carrierCodes.filter(
          (item: any) => item.codeType === 'DOT'
        )[0]?.codeValue || '-',
      mc:
        item?.carrierInfo?.carrierCodes.filter(
          (item: any) => item.codeType === 'MC'
        )[0]?.codeValue || '-',
      scac: getScac(),
      uuid: getUuid(),
      cuid: getCuid(),
      eldVendorDisplayName: item?.lastCheckedEld
        ? item.lastCheckedEld
        : item?.eldInfo[0]?.eldVendorDisplayName || 'Unknown',
      invitedDate:
        moment(item.statusInfo?.invited).format('MM/DD/YYYY') || '-',
      status: item?.statusInfo?.status,
    }
  })
  return tableData
}

const getCarrierTableData = (data: any) => {
  const codeTypes = ['TCID', 'DOT', 'MC', 'SCAC', 'UUID', 'POLICY_ID']
  let index = 0;
  const tableData = data?.map((item: any) => {
    const getScac = () => {
      const val = item?.carrierInfo?.carrierCodes.filter(
        (item: any) => item.codeType === 'SCAC'
      )[0]?.codeValue
      return !val ? '-' : val
    }
    const getCuid = () => {
      const val = item?.carrierInfo?.carrierCodes.filter((item: any) => {
        return !codeTypes.includes(item.codeType)
      })[0]?.codeValue
      return !val ? '-' : val
    }
    const getCuidName = () => {
      const val = item?.carrierInfo?.carrierCodes.filter((item: any) => {
        return !codeTypes.includes(item.codeType)
      })[0]?.codeType
      return !val ? '-' : val
    }
    const getPolicyId = () => {
      const val = item?.carrierInfo?.carrierCodes.filter(
        (item: any) => item.codeType === 'POLICY_ID'
      )[0]?.codeValue
      return !val ? '-' : val
    }
    const tableDataWithEld = item?.eldInfo?.map((eldItem: any) => ({
      index: index++,
      carrierId: item?.carrierInfo?.carrierId,
      carrierName: item?.carrierInfo?.carrierName,
      firstName: item?.contactInfo?.firstName,
      lastName: item?.contactInfo?.lastName,
      email: item?.contactInfo?.email,
      safetyContactEmail: item?.contactInfo?.safetyContactEmail,
      dot:
        item?.carrierInfo?.carrierCodes.filter(
          (codeItem: any) => codeItem.codeType === 'DOT'
        )[0]?.codeValue || '-',
      mc:
        item?.carrierInfo?.carrierCodes.filter(
          (codeItem: any) => codeItem.codeType === 'MC'
        )[0]?.codeValue || '-',
      scac: getScac(),
      cuid: getCuid(),
      cuidName: getCuidName(),
      policyId: getPolicyId(),
      credentialStatus: eldItem?.credentialStatus,
      eldVendor: eldItem?.eldVendor,
      eldVendorDisplayName: eldItem?.eldVendorDisplayName,
      numberOfTrucks: eldItem?.numberOfTrucks || '-',
      numberOfDrivers: eldItem?.numberOfDrivers || '-',
      statusInfo:
        (item?.statusInfo?.onboarded !== null &&
        item?.statusInfo?.onboarded !== undefined)
          ? moment(item?.statusInfo?.onboarded).format('MM/DD/YYYY')
          : '-',
      driverDataEnabled: eldItem?.eldDataSharing?.driverDataEnabled,
      hoursOfServiceDataEnabled: eldItem?.eldDataSharing?.hoursOfServiceDataEnabled,
      engineDataEnabled: eldItem?.eldDataSharing?.engineDataEnabled,
      letterOfAuthorizationUrl: eldItem?.letterOfAuthorizationUrl,
      eldLetterOfAuthorizationUrl: eldItem?.eldLetterOfAuthorizationUrl || '',
    }));
    return tableDataWithEld;
  })
  return tableData?.flat();
}

const getSecondaryCarrierTableData = (data: any, codeTypes: any) => {
  let index = 0
  const getCuid = () => {
    const val = data?.masterCarrier?.carrierInfo.carrierCodes.find(
      (codeItem: any) => !codeTypes.includes(codeItem.codeType)
    )
    return !val ? '-' : val.codeValue
  }
  const getPrimaryCode = () => {
    for (const codeType of codeTypes) {
      const val = data?.masterCarrier?.carrierInfo.carrierCodes.find(
        (codeItem: any) => codeItem.codeType === codeType
      )
      if (val) {
        return val.codeValue
      }
    }
    return '-'
  }
  const getPrimaryCodeType = () => {
    for (const codeType of codeTypes) {
      const val = data?.masterCarrier?.carrierInfo.carrierCodes.find(
        (codeItem: any) => codeItem.codeType === codeType
      )
      if (val) {
        return val.codeType.toLowerCase()
      }
    }
    return 'dot'
  }

  const tableData = data?.myAccounts?.map((item: any) => {
    const tableDataWithEld = item?.eldInfo?.map((eldItem: any) => ({
      index: index++,
      carrierId: item?.carrierInfo?.carrierId,
      carrierName: item?.carrierInfo?.carrierName,
      firstName: item?.contactInfo?.firstName,
      lastName: item?.contactInfo?.lastName,
      email: item?.contactInfo?.email,
      [getPrimaryCodeType()]: getPrimaryCode(), // Set codetype and codevalue of master carrier
      cuid: getCuid(),
      eldVendorDisplayName: eldItem?.eldVendorDisplayName,
      eldVendor: eldItem?.eldVendor,
      onboardDate:
        item?.statusInfo?.onboarded !== null &&
        item?.statusInfo?.onboarded !== undefined
          ? moment(item?.statusInfo?.onboarded).format('MMM DD, YYYY')
          : '-',
      letterOfAuthorizationUrl: eldItem?.letterOfAuthorizationUrl,
      status: item?.statusInfo?.status,
      activeVehicles: eldItem?.numberOfTrucks || '-',
    }))
    return tableDataWithEld
  })
  return tableData?.flat()
}

const getMasterCarrierData = (responseData: any, codeTypes: any) => {
  return {
    carrierName: responseData?.masterCarrier?.carrierInfo?.carrierName || '-',
    contactName: responseData?.masterCarrier?.contactInfo
      ? `${responseData.masterCarrier.contactInfo.firstName} ${responseData.masterCarrier.contactInfo.lastName}`
      : '-',
    contactEmail: responseData?.masterCarrier?.contactInfo?.email || '-',
    primaryCode:
      responseData?.masterCarrier?.carrierInfo?.carrierCodes.filter(
        (item: any) => codeTypes.includes(item.codeType)
      )[0]?.codeValue || '-',
    primaryCodeType:
      responseData?.masterCarrier?.carrierInfo?.carrierCodes.filter(
        (item: any) => codeTypes.includes(item.codeType)
      )[0]?.codeType || 'DOT',
    cuid:
      responseData?.masterCarrier?.carrierInfo?.carrierCodes.filter(
        (item: any) => {
          return !codeTypes.includes(item.codeType)
        }
      )[0]?.codeValue || '-',
    cuidType: responseData?.masterCarrier?.carrierInfo?.carrierCodes.find(
      (item: any) => {
        return !codeTypes.includes(item.codeType)
      }
    )?.codeType,
    activeVehicles:
      responseData?.masterCarrier?.carrierInfo?.numberOfTrucks || '-',
  }
}

const getAssetTableData = (data: any) => {
  const tableData = data?.map((item: any) => {
    return {
      assetId: item?.assetId,
      assetEldId: item?.assetEldId ?? "",
      vin: item?.identificationNo ?? "",
      miles: item?.speedIndexDetails?.totalMilesRecorded != null ? item.speedIndexDetails.totalMilesRecorded.toFixed(2) : "-",
      score: item?.score != null ? item.score.toFixed(2) : "-",
      harshIndex: item?.harshEventsIndex != null ? item.harshEventsIndex.toFixed(2) : "-",
      speedIndex: item?.speedIndex != null ? item.speedIndex.toFixed(2) : "-",
      geoIndex: item?.geographicalIndex != null ? item.geographicalIndex.toFixed(2) : "-",
      // updated: item?.updated ?? "",
    };
  });
  return tableData;
};

const getIndexDiff = (riskData: any, totalDiff: number) => {
  if (riskData?.length <= 1 || isNaN(totalDiff)) {
    return {
      diff: '0',
      color: colors.silver40,
      icon: 'icon_up',
    }
  } else {
    return {
      diff: Math.trunc(Math.abs(totalDiff))?.toString(),
      color:
        totalDiff > 0
          ? colors.green40
          : totalDiff < 0
          ? colors.red40
          : colors.silver40,
      icon: totalDiff >= 0 ? 'icon_up' : 'icon_down',
    }
  }
}

export { getInvitedCarrierData, getCarrierTableData, getSecondaryCarrierTableData, getAssetTableData, getIndexDiff, getMasterCarrierData }
