import { embedDashboard } from '@superset-ui/embedded-sdk'
import { Card, Container, Toast as toast } from 'components'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getSupersetGuestToken } from 'api/dashboard'
import { Box } from '@mui/material'
import { reportException } from 'tools/logs'
import RadiusOfOperationFilter from './radiusOfOperationFilter'
import './radiusOfOperation.module.scss'
import GaragingZipcodeConfig from './garagingZipcodeConfig'
import { getGaragingZipcodeConfigs, updateGaragingZipcodeConfigs } from 'api'

interface IRadiusOfOperationProps {
  print?: boolean
  useCarrierFilters: boolean
}

const RadiusOfOperationDashboard: React.FC<IRadiusOfOperationProps> = (
  props
) => {
  const { useCarrierFilters } = props
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [gzcData, setGzcData] = useState<any>([])
  const [currentData, setCurrentData] = useState<any>([])
  const [saveBtnLoader, setSaveBtnLoader] = useState(false)

  const localStorageDashboardId =
    localStorage.getItem('embeddedDashboardIds') ?? JSON.stringify([])
  const radiusOfOperationDashboardId = JSON.parse(localStorageDashboardId)
  const dashboardId: string =
    radiusOfOperationDashboardId?.RADIUS_OF_OPERATION ??
    process.env.REACT_APP_SUPERSET_RADIUS_OF_OPERATION ??
    ''
  const dashboardUrl: string = process.env.REACT_APP_SUPERSET_URL ?? ''
  const hasValidData: boolean =
    (useCarrierFilters &&
      !!localStorage.getItem('carrierCodeType') &&
      localStorage.getItem('carrierCodeType') != '' &&
      !!localStorage.getItem('carrierCodeValue') &&
      localStorage.getItem('carrierCodeValue') != '') ||
    !useCarrierFilters

  const tokenPromiseRef = useRef<Promise<string> | null>(null)

  const getGuestToken = useCallback(() => {
    if (!tokenPromiseRef.current) {
      tokenPromiseRef.current = getSupersetGuestToken(
        dashboardId,
        useCarrierFilters,
        true
      )
        .then((response: any) => response.token)
        .catch((error: any) => {
          reportException(error)
          throw error
        })
    }
    return tokenPromiseRef.current
  }, [dashboardId, useCarrierFilters])

  const dashboardEmbedded = useRef(false)

  useEffect(() => {
    if (hasValidData && !dashboardEmbedded.current) {
      loadSupersetDashboard()
    }
  }, [dashboardEmbedded, getGuestToken])

  useEffect(() => {
    callGetGzc()
  }, [])

  const loadSupersetDashboard = useCallback(async () => {
    embedDashboard({
      id: dashboardId,
      supersetDomain: dashboardUrl,
      mountPoint: document.getElementById('setContainer') as HTMLElement,
      debug: true,
      fetchGuestToken: getGuestToken,
      dashboardUiConfig: {
        hideTitle: true,
        filters: {
          expanded: true,
        },
      },
    })
    dashboardEmbedded.current = true
  }, [])

  const callGetGzc = useCallback(async () => {
    setLoading(true)
    getGaragingZipcodeConfigs(
      localStorage.getItem('carrierId') ?? '',
      localStorage.getItem('carrierCodeType') ?? '',
      localStorage.getItem('carrierCodeValue') ?? ''
    )
      .then((res: any) => {
        setGzcData(res)
        setCurrentData(res)
      })
      .catch((error: any) => {
        reportException(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const callUpdateGzc = useCallback(async () => {
    setSaveBtnLoader(true)
    const payload = currentData
      .filter((row: any) => {
        const originalRow = gzcData.find(
          (asset: any) => asset.assetEldId === row.assetEldId
        )
        return originalRow && originalRow.manualGzc !== row.manualGzc
      })
      .map((row: any) => ({
        assetEldId: row.assetEldId,
        manualGzc: row.manualGzc,
      }))

      updateGaragingZipcodeConfigs(
      payload,
      localStorage.getItem('carrierId') ?? '',
      localStorage.getItem('carrierCodeType') ?? '',
      localStorage.getItem('carrierCodeValue') ?? ''
    )
      .then((res: any) => {
        toast({ id: 'updateGzc', type: 'success', subTitle: res?.message })
      })
      .catch((error: any) => {
        reportException(error)
      })
      .finally(() => {
        setSaveBtnLoader(false)
        setIsDialogOpen(false)
        callGetGzc()
        loadSupersetDashboard()
      })
  }, [currentData, gzcData])

  const handleSave = () => {
    callUpdateGzc()
  }

  const handleUpdate = (updatedRow: any) => {
    setCurrentData((prevData: any) =>
      prevData.map((row: any) =>
        row.assetEldId === updatedRow.assetEldId ? updatedRow : row
      )
    )
  }

  const handleOpen = () => {
    setIsDialogOpen(true)
  }

  const handleClose = () => {
    setCurrentData(gzcData)
    setIsDialogOpen(false)
  }

  const updateDashboardAfterUpdate = () => {
    setSaveBtnLoader(false)
    callGetGzc()
    loadSupersetDashboard()
  }

  return (
    <>
      <RadiusOfOperationFilter
        onButtonClick={handleOpen}
        reloadDashboard={loadSupersetDashboard}
      />
      <Card>
        <Container>
          {hasValidData ? (
            <div
              id="setContainer"
              style={{ width: '90vw', height: '75vh' }}
            />
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                margin: 'auto',
                height: '75vh',
              }}
            >
              <h5>No data</h5>
            </Box>
          )}
        </Container>
      </Card>
      <GaragingZipcodeConfig
        isOpen={isDialogOpen}
        loading={loading}
        currentData={currentData}
        updateCurrentData={handleUpdate}
        onClose={handleClose}
        onSave={handleSave}
        btnLoader={saveBtnLoader}
        updateDashboardAfterUpdate={updateDashboardAfterUpdate} 
      />
    </>
  )
}
export default RadiusOfOperationDashboard
