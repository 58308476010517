const regex = {
  EMAIL_REGEX_PATTERN:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  NUMERIC_ONLY: /^(0|[1-9][0-9]*)$/,
  NUMERIC_ONLY_EXACT_LENGTH_9: /^([0-9]{9})$/,
  EMAIL_LIST_REGEX_PATTERN:
  /^\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}(\s*,\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63})*\s*$/,
}

export default regex
