import request from 'tools/request'

export const getSetting = async (notificationType: string) => {
  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}communication-center/get-setting/?notificationType=${notificationType}`)
  return res?.data
}

export const createSetting = async (payLoad: any) => {
  const res = await request.post(
      `${process.env.REACT_APP_CARRIER_API_URL_V2}communication-center/create-setting`, payLoad)
  return res?.data
}

export const updateSetting = async (payLoad: any) => {
  const res = await request.put(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}communication-center/update-setting`, payLoad)
  return res?.data
}

export const getFrequencyOptions = async (notificationType: string) => {
  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}communication-center/list-frequencies?notificationType=${notificationType}`)
  return res?.data
}

export const getUserEmailOptions = async () => {
  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}communication-center/list-user-emails`)
  return res?.data
}