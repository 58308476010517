import { useRef, useState,forwardRef,useImperativeHandle} from 'react'
import { Blade} from 'components'
import { InputFormField } from "components/hookForm"
import { updateBulkUpdateGZC } from 'api'
import { Container, Toast as toast} from 'components'
import { reportException } from 'tools/logs';
import { useForm, FormProvider } from 'react-hook-form'
import { IconButton } from '@mui/material'
import EditIcon from "@mui/icons-material/Edit";
import { validateLengthGZC } from 'tools/validators'

export interface ApiRefBulkGZC{setListIndexGZC: (params: any) => void }
interface ApiProps{handleSaveSon: () => void, row: any}
interface ApiRowProps{row: any}
interface InputManualGZC {
  getValue: () => any
  validateForm?: () => Promise<boolean>;
}

const InputBulk = forwardRef<InputManualGZC,ApiRowProps>((props, ref)=>{
  const [valueBulkManualGZC, setValueBulkManualGZC]  = useState(props.row.manualGzc)
    
  const getValue = ()=>{
    return getValues("manual_GZC_edit")
  }

  const methods = useForm<any>({
    reValidateMode: 'onChange',
    mode: 'all',
    defaultValues: {
      manual_GZC_edit: valueBulkManualGZC ?? props.row.manualGzc ?? ""
    }
  })

  const { getValues ,formState: { errors }} = methods

  useImperativeHandle(ref,()=>({
    getValue,
    validateForm: () => methods.trigger("manual_GZC_edit")
  }))

  return (
        <div style={{'display': 'flex', 'flexDirection': 'column'}}>
          <FormProvider {...methods}>
          <InputFormField
              id={"id_" + props.row.assetEldId}
              name="VIN"
              label={"VIN"}
              maxLength={100}
              disabled={true}
              value={props.row.vin}
            />
            <InputFormField
              id={"autoGZC_" + props.row.assetEldId}
              name="automaticGzc"
              label={"Automatic GZC"}
              maxLength={100}
              disabled={true}
              value={props.row.automaticGzc}
            />
            <InputFormField 
                id={"manualGZC_" + props.row.assetEldId}
                type="text" 
                label="Manual GZC" 
                name="manual_GZC_edit"
                validate={validateLengthGZC}                
                className="border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            </FormProvider>  
         </div> 
  )
})

const EditIndividualManualGZC = forwardRef<ApiRefBulkGZC,ApiProps>((props, ref)=>{

  const [hasChecked, setHasChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const inputRef = useRef<InputManualGZC>(null)
  const [hasCheckedEdit, setHasCheckedEdit] = useState(false)
 

  const handleCloseEditBulkManualGZC = () => {
    setHasChecked(false)
    setHasCheckedEdit(false)
  }

  const handleSubmitBulkManualGZC = async () => {
    const isValid = await inputRef.current?.validateForm?.();
    let listAsset = [props.row.assetEldId]
      const payload={      
        listAssetEldId: listAsset ? listAsset : null,      
        manualGzc: inputRef.current?.getValue()?inputRef.current?.getValue():null
      }  
    if (!isValid) {
      return; // Stop submission if validation fails
    }else{
      props.row.manualGzc=inputRef.current?.getValue()
      setLoading(true)
    }
    
    updateBulkUpdateGZC(
      payload,
      localStorage.getItem('carrierId') ?? '',
      localStorage.getItem('carrierCodeType') ?? '',
      localStorage.getItem('carrierCodeValue') ?? ''
    )
      .then((res: any) => {
        toast({ id: 'updateBulkGzc', type: 'success', subTitle: res?.message })
      })
      .catch((error: any) => {
        reportException(error)
      })
      .finally(() => {       
        setLoading(false)
        handleCloseEditBulkManualGZC() 
        props.handleSaveSon()
      })

  }
 
  const handleOpenModalBulkGZC = (e: any) => {
      setHasChecked(false)
      setHasCheckedEdit(true)
      e.stopPropagation();

  }
 
  return (<div> 
  <Container>
        <div> 
            <IconButton 
              onClick={(e) => handleOpenModalBulkGZC(e)}>
              <EditIcon/>              
            </IconButton>                  
        </div>
  </Container>

  <Blade
  open={hasCheckedEdit}
  title={"Edit Manual GZC"}
  onClose={handleCloseEditBulkManualGZC}
  bodyNode={<InputBulk ref={inputRef} row={props.row}></InputBulk>}   
  footerBtns={{
    primary: {
      id: "createOrUpdateBulkManualGZC",
      label: 'Save',
      onClick: handleSubmitBulkManualGZC,
      disabled: false,  
      loading: loading    
    },
    secondary: {
      id: "closeBulkManualGZC",
      label: 'Close',
      variant: 'outlined',
      onClick: handleCloseEditBulkManualGZC,
    },
  }}
/>
</div>
)
})

export default EditIndividualManualGZC 
