import strings from 'strings'

export function passwordStrengthValidator(password: string) {
  const regularExpression = new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$]).{8,}$"
  )
  if (!regularExpression.test(password)) {
    return strings.PASSWORD_ERROR_FORMAT
  }
}

export function emailValidator(email: string) {
  const regularExpression = new RegExp(
    '^[a-zA-Z0-9._%+-/]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'
  )

  if (!regularExpression.test(email)) {
    return strings.EMAIL_ERROR_FORMAT
  }
}

export function multipleEmailsValidator(emails: string) {
  if (emails){ 
  const regularExpression = new RegExp(
    '^\s*[a-zA-Z0-9._%+-/]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}(\s*,\s*[a-zA-Z0-9._%+-/]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,})*\s*$'
  )

  if (!regularExpression.test(emails)) {
    return strings.EMAIL_ERROR_FORMAT_LIST
  }}
}

export function validateLengthGZC(GZC: string) {  
  // If GZC is empty or undefined, return error
  if (!GZC) {
    return "Manual GZC is required";
  }
  
  // Check if GZC matches the pattern (5 digits)
  const regularExpression = new RegExp('^[0-9]{5}$');
  if (!regularExpression.test(GZC)) {
    return 'Manual GZC must be a 5-digit number';     
  }  
  return true; // Return true for successful validation
}