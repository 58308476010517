import { Container, Icon, Spinner, Tooltip, TypoGraph } from 'components'
import styles from './summaryPanel.module.scss'
import colors from 'theme/colors'
import { generateVariationTooltip } from 'tools/helper'
import { Box } from '@mui/material'

interface IRiskSummaryPanelProps {
    print: boolean,
    isLoading: boolean,
    summaryData: any,
    isAgent: boolean
}

const RiskSummaryPanel: React.FC<IRiskSummaryPanelProps> = (props) => {
    const { print, summaryData, isLoading, isAgent } = props

    const RenderDiffCell = (props: any) => {
        const { title, index, color, icon, tooltip, bottomLabelContent } = props
        return (
          <Container>
            <Box
              display="flex"
              justifyContent="space-around"
              alignItems="center"
            >
              <TypoGraph
                content={title}
                color={colors.white}
                sx={{ fontSize: '1.5rem' }}
              />
              <Tooltip
                title={tooltip}
                containerClassName="ellipsis"
                maxWidth={150}
              >
                <Container className="indexContainer">
                  <Icon name={icon} size={15} color={color} />
                  <TypoGraph
                    content={index}
                    color={color}
                    sx={{ fontSize: '1rem!important' }}
                  />
                </Container>
              </Tooltip>
            </Box>
            {bottomLabelContent && (
              <TypoGraph
                variant="subtitle2"
                content={bottomLabelContent}
                color={colors.white}
              />
            )}
          </Container>
        )
    }

    return (
        <Container
            className={`${styles.summaryContainer} ${
                print ? styles.printContainer : ''
            }`}
        >
            {(isLoading && 
            <Spinner />
            ) || (
            <Container className={styles.summaryMainContainer}>
                <Container className={styles.summaryHeaderContainer}>
                    <Container className={styles.summaryList}>
                        <Container className={styles.itemContainer}>
                            <Icon name="summary_carriers" size={32} />
                            <Container className={styles.summaryContentContainer}>
                                <TypoGraph
                                variant="subtitle2"
                                color={colors.white}
                                content="Total Carriers"
                                />
                                <Container className={styles.summaryDataContainer}>
                                    <RenderDiffCell
                                        title={(summaryData?.totalCarriers || 0)?.toLocaleString()}
                                        index={summaryData?.totalCarriersVariation && !isNaN(summaryData?.totalCarriersVariation) ? summaryData?.totalCarriersVariation.toFixed(2) + `%` : '-'}
                                        color={colors.silver40}
                                        icon={summaryData?.totalCarriersVariation >= 0 ? "icon_up" : "icon_down"}
                                        tooltip={generateVariationTooltip(summaryData?.totalCarriers, summaryData?.totalCarriersPrev, summaryData?.totalCarriers, true)}
                                    />
                                </Container>
                            </Container>
                        </Container>
                        <Container className={styles.itemContainer}>
                            <Icon name="summary_assets" size={32} />
                            <Container className={styles.summaryContentContainer}>
                                <TypoGraph
                                variant="subtitle2"
                                color={colors.white}
                                content="Total Assets"
                                />
                                <Container className={styles.summaryDataContainer}>
                                    <RenderDiffCell
                                        title={(summaryData?.totalAssets || 0)?.toLocaleString()}
                                        index={summaryData?.totalAssetsVariation && !isNaN(summaryData?.totalAssetsVariation) ? summaryData?.totalAssetsVariation.toFixed(2) + `%` : '-'}
                                        color={colors.silver40}
                                        icon={summaryData?.totalAssetsVariation >= 0 ? "icon_up" : "icon_down"}
                                        tooltip={generateVariationTooltip(summaryData?.totalAssets, summaryData?.totalAssetsPrev, summaryData?.totalAssets, true)}
                                    />
                                </Container>
                            </Container>
                        </Container>
                        <Container className={styles.itemContainer}>
                            <Icon name="summary_risk" size={32} />
                            <Container className={styles.summaryContentContainer}>
                                <TypoGraph
                                variant="subtitle2"
                                color={colors.white}
                                content="Risk Index"
                                />
                                <Container className={styles.summaryDataContainer}>
                                    <RenderDiffCell
                                        title={(summaryData?.riskIndex?.toFixed(1) || 0)?.toLocaleString()}
                                        index={summaryData?.riskVariation && !isNaN(summaryData?.riskVariation) ? summaryData?.riskVariation.toFixed(2) + `%` : '-'}
                                        color={colors.silver40}
                                        icon={summaryData?.riskVariation >= 0 ? "icon_up" : "icon_down"}
                                        tooltip={generateVariationTooltip(summaryData?.riskIndex, summaryData?.riskIndexPrev, summaryData?.riskIndex, true)}
                                        bottomLabelContent={'TC Avg: ' + (summaryData?.lastRiskIndexAverage || '-')}
                                    />
                                </Container>
                            </Container>
                        </Container>
                        <Container className={styles.itemContainer}>
                            <Icon name="summary_harsh" size={32} />
                            <Container className={styles.summaryContentContainer}>
                                <TypoGraph
                                    variant="subtitle2"
                                    color={colors.white}
                                    content="Harsh Events Index"
                                />
                                <Container className={styles.summaryDataContainer}>
                                    <RenderDiffCell
                                        title={(summaryData?.harshEventsIndex?.toFixed(1) || 0)?.toLocaleString()}
                                        index={summaryData?.harshEventsVariation && !isNaN(summaryData?.harshEventsVariation) ? summaryData?.harshEventsVariation.toFixed(2) + `%` : '-'}
                                        color={colors.silver40}
                                        icon={summaryData?.harshEventsVariation >= 0 ? "icon_up" : "icon_down"}
                                        tooltip={generateVariationTooltip(summaryData?.harshEventsIndex, summaryData?.harshEventsIndexPrev, summaryData?.harshEventsIndex, true)}
                                        bottomLabelContent={'TC Avg: ' + (summaryData?.lastHarshEventsIndexAverage || '-')}
                                    />
                                </Container>
                            </Container>
                        </Container>
                        <Container className={styles.itemContainer}>
                            <Icon name="summary_speed" size={32} />
                            <Container className={styles.summaryContentContainer}>
                                <TypoGraph
                                variant="subtitle2"
                                color={colors.white}
                                content="Speeding Index"
                                />
                                <Container className={styles.summaryDataContainer}>
                                    <RenderDiffCell
                                        title={(summaryData?.speedIndex?.toFixed(1) || 0)?.toLocaleString()}
                                        index={summaryData?.speedVariation && !isNaN(summaryData?.speedVariation) ? summaryData?.speedVariation.toFixed(2) + `%` : '-'}
                                        color={colors.silver40}
                                        icon={summaryData?.speedVariation >= 0 ? "icon_up" : "icon_down"}
                                        tooltip={generateVariationTooltip(summaryData?.speedIndex, summaryData?.speedIndexPrev, summaryData?.speedIndex, true)}
                                        bottomLabelContent={'TC Avg: ' + (summaryData?.lastSpeedIndexAverage || '-')}
                                    />
                                </Container>
                            </Container>
                        </Container>
                        {!isAgent && <Container className={styles.itemContainer}>
                            <Icon name="summary_geo" size={32} />
                            <Container className={styles.summaryContentContainer}>
                                <TypoGraph
                                    variant="subtitle2"
                                    color={colors.white}
                                    content="Geographic Index"
                                />
                                <Container className={styles.summaryDataContainer}>
                                    <RenderDiffCell
                                        title={(summaryData?.geographicIndex?.toFixed(1) || 0)?.toLocaleString()}
                                        index={summaryData?.geographicVariation && !isNaN(summaryData?.geographicVariation) ? summaryData?.geographicVariation.toFixed(2) + `%` : '-'}
                                        color={colors.silver40}
                                        icon={summaryData?.geographicVariation >= 0 ? "icon_up" : "icon_down"}
                                        tooltip={generateVariationTooltip(summaryData?.geographicIndex, summaryData?.geographicIndexPrev, summaryData?.geographicIndex, true)}
                                        bottomLabelContent={'TC Avg: ' + (summaryData?.lastGeographicIndexAverage || '-')}
                                    />
                                </Container>
                            </Container>
                        </Container>}                        
                    </Container>
                </Container>
            </Container>
            )}
        </Container>
    )
}

export default RiskSummaryPanel