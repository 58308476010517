import React, { useImperativeHandle, useState } from 'react';
import { Icon, TypoGraph, } from 'components';
import strings from 'strings';
import { SafetyDetails } from 'interfaces/IClaimsSearching';
import { formatEventTypeLabel, getEventIcon } from 'tools/helper';
import { Button, Grid, Paper, useMediaQuery, useTheme } from '@mui/material';
import LoadingWrapper from 'components/loadingWrapper';
import moment from 'moment';

interface SafetyEventDetailsProps {
    selectedEvent: SafetyDetails | undefined;
    selectedCarrierName: string;
    loading: boolean;
    ref: any;
}

const t = strings.CLAIMS_DETAILS

const ClaimsSafetyDetails: React.FC<SafetyEventDetailsProps> = React.forwardRef((props, ref) => {
    const { selectedEvent, loading, selectedCarrierName } = props
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
    const hasVideo = !!selectedEvent?.cameraData[0]?.videoUrl
    const [currentIndex, setCurrentIndex] = useState(0)
    const driverVisibleName = selectedEvent?.driver?.firstName 
        ? (selectedEvent?.driver?.firstName + (selectedEvent?.driver?.lastName 
            ? " " + selectedEvent?.driver?.lastName.charAt(0) + "." 
            : "")) 
        : selectedEvent?.driver?.driverName ? selectedEvent?.driver?.driverName : "-"

    useImperativeHandle(ref, () => ({
        handleDownload: () => {
            downloadVideo()
        }
    }))

    const isVideoExpired = (url: string): boolean => {
        try {
            const urlParams = new URLSearchParams(new URL(url).search)
            const responseExpires = urlParams.get('response-expires')

            if (responseExpires) {
                const expirationDate = moment(decodeURIComponent(responseExpires), [
                    'ddd, D MMM YYYY HH:mm:ss [GMT]',
                    'YYYY-MM-DDTHH:mm:ss.SSSZ',
                    'YYYY-MM-DD HH:mm:ss',
                    'MMM D, YYYY h:mm A',
                    'X',
                    'x',
                ])

                if (expirationDate.isValid()) {
                    return expirationDate.isBefore(moment())
                }
            }
            return false
        } catch (error) {
            console.error('Invalid URL:', error)
            return true
        }
    }

    const handleNext = () => {
        if (selectedEvent?.cameraData && currentIndex < selectedEvent?.cameraData?.length - 1) {
            setCurrentIndex(currentIndex + 1)
        }
    }

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1)
        }
    }

    const currentVideo = selectedEvent?.cameraData?.[currentIndex]
    const isExpired = currentVideo?.videoUrl ? isVideoExpired(currentVideo.videoUrl) : true

    const downloadVideo = () => {
        if (currentVideo) {
            fetch(currentVideo.videoUrl)
                .then((response) => response.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob)
                    const anchor = document.createElement("a")
                    anchor.href = url
                    anchor.download = createVideoFileName()
                    anchor.style.display = "none"
                    document.body.appendChild(anchor)
                    anchor.click();
                    document.body.removeChild(anchor)
                    window.URL.revokeObjectURL(url)
                })
                .catch((error) => {
                    console.error("Error downloading video:", error);
                })
        }
    }

    const createVideoFileName = () => {
        const date = selectedEvent?.time
            ? new Date(selectedEvent?.time).toLocaleString()
            : '';

        const carrierName = selectedCarrierName ?? '-';
        const eventType = selectedEvent?.type ?? '-';
        const cameraPosition = currentVideo?.position ?? '-';

        return `${carrierName} ${eventType} ${cameraPosition} ${date}`;
    }

    return (
        <LoadingWrapper isLoading={loading}>
            <Grid container spacing={2} padding={2}>
                {/* Event Header */}
                <Grid container item xs={12}>
                    <Grid xs={8}>
                        <TypoGraph variant="h4" component="h2" gutterBottom mb={1}>
                            {formatEventTypeLabel(selectedEvent?.type)} {t.EVENT}
                        </TypoGraph>
                        <TypoGraph variant="subtitle1" color="textSecondary" mb={1}>
                            {`${t.EVENT} ${t.ID}`}: {selectedEvent?.id} | {t.TIME}:{" "}
                            {selectedEvent?.time ? new Date(selectedEvent?.time).toLocaleString() : "N/A"}
                        </TypoGraph>
                        <TypoGraph variant="subtitle1" color="textSecondary">
                            {t.SPEED}: {selectedEvent?.speed?.toFixed(2) ?? '-'} {' | '}
                            {t.LATITUDE}: {selectedEvent?.latitude} {' | '}
                            {t.LONGITUDE}: {selectedEvent?.longitude}
                        </TypoGraph>
                    </Grid>
                    <Grid xs={4} display={'flex'} justifyContent={'end'}>
                        <Icon
                            size={60}
                            materialIcon={getEventIcon(selectedEvent?.type ?? '')}
                            width={100}
                            height={100}
                        />
                    </Grid>
                </Grid>

                {/* Vehicle Details */}
                <Grid spacing={2} item xs={12}>
                    <Paper elevation={4} sx={{ padding: 2, width: '100%' }}>
                        <Grid item xs={12}>
                            <TypoGraph variant="h5" sx={{ fontWeight: 'bold' }}>{t.VEHICLE_DETAILS}</TypoGraph>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TypoGraph variant="body1">{t.NAME}: {selectedEvent?.vehicle?.assetName ?? '-'}</TypoGraph>
                                <TypoGraph variant="body1">{t.TYPE}: {selectedEvent?.vehicle?.assetType ?? '-'}</TypoGraph>
                                <TypoGraph variant="body1">{t.LICENSE_PLATE}: {selectedEvent?.vehicle?.licensePlateNo ?? '-'}</TypoGraph>
                                <TypoGraph variant="body1">{t.VIN}: {selectedEvent?.vehicle?.vin ?? '-'}</TypoGraph>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TypoGraph variant="body1">{t.SERIAL_NO}: {selectedEvent?.vehicle?.assetSerialNo ?? '-'}</TypoGraph>
                                <TypoGraph variant="body1">{t.MAKE}: {selectedEvent?.vehicle?.make ?? '-'}</TypoGraph>
                                <TypoGraph variant="body1">{t.MODEL}: {selectedEvent?.vehicle?.model ?? '-'}</TypoGraph>
                                <TypoGraph variant="body1">{t.MODEL_YEAR}: {selectedEvent?.vehicle?.modelYear ?? '-'}</TypoGraph>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                {/* Driver Details */}
                <Grid item xs={12} mb={0}>
                    <Paper elevation={4} sx={{ padding: 2 }}>
                        <TypoGraph variant="h5" sx={{ fontWeight: 'bold' }}>{t.DRIVER_DETAILS}</TypoGraph>
                        <TypoGraph variant="body1">{t.NAME}: {driverVisibleName}</TypoGraph>
                        <TypoGraph variant="body1">{t.LICENSE_NO}: {selectedEvent?.driver?.licenseNo ?? '-'}</TypoGraph>
                        <TypoGraph variant="body1">{t.STATE}: {selectedEvent?.driver?.licenseState ?? '-'}</TypoGraph>
                        <TypoGraph variant="body1">{t.PHONE}: {selectedEvent?.driver?.phoneNo ?? '-'}</TypoGraph>
                        <TypoGraph variant="body1">{t.EMAIL}: {selectedEvent?.driver?.emailId ?? '-'}</TypoGraph>
                    </Paper>
                </Grid>

                {/* G-Force Data */}
                <Grid item xs={12}>
                    <Paper elevation={4} sx={{ padding: 2 }}>
                        <TypoGraph variant="h5" sx={{ fontWeight: 'bold' }}>{t.GFORCE_DATA}</TypoGraph>
                        {selectedEvent?.gforce && selectedEvent?.gforce?.length > 0 ? (
                            selectedEvent?.gforce?.map((g, index) => (
                                <TypoGraph key={index} variant="body2">
                                    {t.TIME_LABEL}: {new Date(g?.dateTime).toLocaleString()} | {t.VALUE}: {g?.value ?? '-'}g
                                </TypoGraph>
                            ))
                        ) : (
                            <TypoGraph variant="body2" sx={{ fontStyle: 'italic', color: 'gray' }}>
                                {t.NO_GFORCE_DATA}
                            </TypoGraph>
                        )}
                    </Paper>
                </Grid>

                {/* Video Carousel */}
                <Grid item xs={12} maxHeight={400} height={350} mb={2}>
                    <TypoGraph variant="h5" sx={{ fontWeight: 'bold' }}>{t.CAMERA_POSITION}</TypoGraph>
                    <TypoGraph variant="subtitle1" color="textSecondary" mb={1}>{formatEventTypeLabel(currentVideo?.position) ?? '-'}</TypoGraph>
                    {hasVideo && !loading && currentVideo && !isExpired ? (
                        <>
                            <iframe
                                width="100%"
                                height={isSmallScreen ? 200 : 350}
                                src={currentVideo.videoUrl}
                                title={`${t.CAMERA_POSITION} - ${currentVideo?.position}`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                            <Grid item xs={12} display="flex" justifyContent="space-between" mt={1}>
                                <Button
                                    sx={{ borderRadius: 25, minWidth: 100, marginBottom: 2 }}
                                    variant="outlined"
                                    color='primary'
                                    onClick={handlePrevious}
                                    disabled={currentIndex === 0}
                                >
                                    {t.PREVIOUS}
                                </Button>
                                <TypoGraph>
                                    {t.VIDEO} {currentIndex + 1} {'of'} {selectedEvent?.cameraData?.length}
                                </TypoGraph>
                                <Button
                                    sx={{ borderRadius: 25, minWidth: 100, marginBottom: 2 }}
                                    variant="outlined"
                                    onClick={handleNext}
                                    disabled={currentIndex === selectedEvent?.cameraData?.length - 1}
                                >
                                    {t.NEXT}
                                </Button>
                            </Grid>
                        </>
                    ) : (
                        <Paper
                            elevation={4}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                backgroundColor: 'grey.200',
                                textAlign: 'center',
                                padding: 2,
                            }}
                        >
                            <TypoGraph variant="body1" color="textSecondary" sx={{ fontStyle: 'italic' }}>
                                {isExpired && hasVideo ? t.VIDEO_EXPIRED : t.VIDEO_NOT_AVAILABLE}
                            </TypoGraph>
                        </Paper>
                    )}
                </Grid>
            </Grid>
        </LoadingWrapper>
    )
})

export default ClaimsSafetyDetails;