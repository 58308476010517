import React from 'react'
import { Route, Routes, RouteProps } from 'react-router-dom'
import SettingsPage from 'pages/settingsPage'
import CompanyConfigPage from 'pages/settingsPage/companyConfig'
import EmailTemplateList from 'pages/settingsPage/emailTemplate'
import EmailAccountsList from 'pages/settingsPage/emailAccounts'
import WhileLabel from 'pages/settingsPage/whiteLabel'
import UserManagement from 'pages/settingsPage/userManagement'
import Page404 from 'pages/page404'
import strings from 'strings'
import AgentManagement from 'pages/settingsPage/agentManagement'
import TagManagement from 'pages/settingsPage/tagManagement'
import CommunicationCenter from 'pages/settingsPage/communicationCenter'

const CompaniesRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route path={strings.ROUTES.CURRENT_PATH} element={<SettingsPage />} />
      <Route path={strings.ROUTES.SETTINGS_COMPANY_CONFIG} element={<CompanyConfigPage />} />
      <Route path={strings.ROUTES.SETTINGS_EMAIL_TEMPLATES} element={<EmailTemplateList />} />
      <Route path={strings.ROUTES.SETTINGS_EMAIL_ACCOUNTS} element={<EmailAccountsList />} />
      <Route path={strings.ROUTES.SETTINGS_WHITE_LABEL} element={<WhileLabel />} />
      <Route path={strings.ROUTES.SETTINGS_USERS} element={<UserManagement />} />
      <Route path={strings.ROUTES.SETTINGS_AGENTS} element={<AgentManagement />} />
      <Route path={strings.ROUTES.SETTINGS_TAGS} element={<TagManagement />} />
      <Route path={strings.ROUTES.WILDCARD} element={<Page404 />} />
      <Route path={strings.ROUTES.SETTINGS_COMMUNICATION_CENTER} element={<CommunicationCenter/>} />
    </Routes>
  )
}

export default CompaniesRoutes
