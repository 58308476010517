import { useEffect, useState } from "react"
import { Button, Card, Container, Divider, TypoGraph, Toast as toast } from "components"
import { CircularProgress } from "@mui/material"
import { FormProvider, useForm } from "react-hook-form"
import { getSetting, updateSetting, getFrequencyOptions, createSetting } from 'api'
import { DropdownFormField, InputFormField } from "components/hookForm"
import { reportException } from 'tools/logs'
import { multipleEmailsValidator } from 'tools/validators'
import strings from "strings"
import styles from '../../communicationCenter.module.scss'

interface ISelectOption {
  value: string;
  code: string;
}

interface INotificationSettingsProps {
    id: string,
    type: string,
    title: string,
    emailOptions: ISelectOption[]
}

const NotificationSetting: React.FC<INotificationSettingsProps> = (props) => {
    const [loading, setLoading] = useState(false)
    const [settingId, setSettingId] = useState("")
    const [selectedFrequencyRow, setSelectedFrequencyRow] = useState<string | undefined>();
    const [selectedUserEmailRow, setSelectedUserEmailRow] = useState<string | undefined>();
    const [selectFrequencyList, setSelectFrequencyList] = useState<ISelectOption[]>([])
    const [showButtonCreate, setShowButtonCreate] = useState(false);

    const { id, type, title, emailOptions } = props
    const t = strings.COMMUNICATION_CENTER
    const payloadConfigurationMap : Record<string, string> = {
      [t.RISK_SCORE_NOTIFICATION_TYPE]: 'riskScoreConfiguration',
      [t.DISCONNECTED_CARRIERS_NOTIFICATION_TYPE]: 'disconnectedCarriersConfiguration'
    }
    const payloadObjectName = payloadConfigurationMap[type] || 'configuration'

    const methods = useForm<any>({
        reValidateMode: 'onChange',
      })
    const { setValue, handleSubmit } = methods

    const companyId = localStorage.getItem('companyId')
      
    useEffect(() => {
      getFrequencyOptions(type)
        .then((response: any) => {
          setSelectFrequencyList(response)
        })
        .catch((error: any) => {
          reportException(error)
        })
    }, [])

    useEffect(() => {
      setLoading(true)
      if (selectFrequencyList && emailOptions) {
        getSetting(type)
          .then((res: any) => {
            const dataStart = res?.[payloadObjectName];
            const hasValidData = !!dataStart && Object.keys(dataStart).length > 0 && 
                Object.values(dataStart).some(value => value !== null && value !== undefined);
          
            if (hasValidData) {
              setShowButtonCreate(false)
              setValue("userEmail", dataStart?.userEmail || '')
              setValue("frequency", dataStart?.frequency || '')
              setSelectedFrequencyRow(dataStart?.frequency || '')
              setSelectedUserEmailRow(dataStart?.userEmail || '')
              setSettingId(dataStart?.id || '')
              setValue("manualEmailsCC", dataStart?.manualEmailsCC || '')
            } else {
              const defaultEmailOption = emailOptions[0]?.code || '';
              const defaultFrequency = selectFrequencyList[0]?.code || '';

              setValue("userEmail", defaultEmailOption)
              setValue("frequency", defaultFrequency)
              setSelectedFrequencyRow(defaultFrequency)
              setSelectedUserEmailRow(defaultEmailOption)
              setShowButtonCreate(true)
            }}
          )
          .catch((e) => {
            reportException(e)
          })
          .finally(() => setLoading(false))
      }
    }, [selectFrequencyList, emailOptions])

    const onSubmit = (data:any) => {
      setLoading(true)   
      const payLoad: any = {
        [payloadObjectName]: {
          companyId: companyId,
          manualEmailsCC: data.manualEmailsCC,
          frequency: data.frequency,
          userEmail: data.userEmail,
          notificationType: type
        }
      }
      
      createSetting(payLoad)
        .then((res: any) => {
            const data = res?.[payloadObjectName]
            toast({
              id: "createUserToast",
              subTitle: res?.description ?? t.NOTIFICATION_SETTINGS_CREATE_SUCCESS,
            })

            setSettingId(data?.id)
            setSelectedFrequencyRow(data?.frequency)
            setSelectedUserEmailRow(data?.userEmail)
            setShowButtonCreate(false)
          })
          .catch((e) => {
            console.log(e)
            reportException(e)
          })
        .finally(() => {
            setLoading(false)
        })
      }
  
    const onSubmitUpdate = (data:any) => {
      setLoading(true)
      const payLoad: any = {
        [payloadObjectName]: {
          id: settingId,
          userEmail: data.userEmail,
          manualEmailsCC: data.manualEmailsCC,
          frequency: data.frequency,
          companyId: companyId,
          notificationType: type
        }
      }

      updateSetting(payLoad)        
        .then((res: any) => {
          toast({
            id: "updateUserToast",
            subTitle: res?.description ?? t.NOTIFICATION_SETTINGS_UPDATE_SUCCESS,
          })
          setShowButtonCreate(false)
        })
        .catch((e) => {
          console.log(e)
          reportException(e)
        })
        .finally(() => {
          setLoading(false)
        })
    }
    
    const handleFrequencyChange = (e: any) => {
      const value = e.target.value
      setSelectedFrequencyRow(value)      
    }

    const handleUserEmailChange = (e: any) => {
      const value = e.target.value
      setSelectedUserEmailRow(value) 
    }

    return (
        <Card sxContainer={{ marginTop: '1rem' }}>
        {loading ? (
          <Container className={styles.loader}>
            <CircularProgress />
          </Container>
        ) : ( 
          <>
            <Container id={id}> 
              <FormProvider {...methods}>
                <Container>
                  <div style={{'display': 'inline-flex'}}>    
                    <div> 
                      <Container>
                        <TypoGraph
                          variant="h2"
                          sx={{ paddingTop: 1 }}
                          content={title ? title + " " + t.NOTIFICATION_SETTINGS : t.NOTIFICATION_SETTINGS}
                        />
                      </Container>
                    </div>
                    <div style={{'display':'inline-flex','flex': 'auto','flexDirection':'row-reverse'}}>
                      <div> 
                        <Container >
                          {showButtonCreate ? (
                          <Button
                              id={'1'}
                              type="submit"
                              variant="contained"
                              color="primary"
                              label={t.CREATE_SETTING}
                              onClick={handleSubmit((data) => onSubmit(data))}
                            />
                          ) : (                    
                            <Button
                              id={'2'}
                              type="submit"
                              variant="contained"
                              color="primary"
                              label={t.UPDATE_SETTING}
                              onClick={handleSubmit((data) => onSubmitUpdate(data))}
                            />)}
                        </Container>
                      </div>
                    </div>
                  </div>
                </Container>
                <Divider />
                <Container className={styles.textFieldContainer}>               
                  <DropdownFormField
                    id="userEmail"
                    name="userEmail"
                    label={t.USER_MAIL}
                    options={emailOptions.map((opt: any) => {
                      return {
                        label: opt.value,
                        value: opt.code,
                      }
                    })}
                    required={true}
                    onFieldChange={(event: any) => handleUserEmailChange(event)}
                    value={selectedUserEmailRow || ''} 
                  />
                  <InputFormField
                    id="manualEmailsCC"
                    name="manualEmailsCC"
                    label={t.USER_MAIL_CC}
                    required={false}
                    validate={multipleEmailsValidator}       
                  />
                  <DropdownFormField
                    id="frequency"
                    name="frequency"
                    label={t.FREQUENCY}
                    options={selectFrequencyList.map((opt: any) => {
                      return {
                        label: opt.value,
                        value: opt.code,
                      }
                    })}
                    required={true}
                    onFieldChange={(event: any) => handleFrequencyChange(event)}
                    value={selectedFrequencyRow || ''}
                  />
                </Container>
              </FormProvider> 
            </Container>           
          </>
        )}         
      </Card>
    )
}

export default NotificationSetting